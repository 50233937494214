import { __decorate } from "tslib";
import { Vue, Component } from 'vue-property-decorator';
import { mainStore, } from '@/utils/store-accessor';
let List = class List extends Vue {
    constructor() {
        super(...arguments);
        this.loading = false;
        this.account_id = {};
    }
    get userProfile() {
        return mainStore.userProfile;
    }
    // public async buscar() {
    //     this.loading = true;
    //     this.data = await drivechatStore.getOverview();
    //     this.loading = false;
    // }
    async mounted() {
        this.account_id = this.userProfile.company.chatwoot_account_id;
    }
};
List = __decorate([
    Component
], List);
export default List;
